import React from 'react';
import './Style.css';

const Home = () => {
  const handleDownloadClick = () => {
    window.location.href = 'https://rcda.sharefile.com/filedrop/dx84ca66-e66a-4aba-a370-f1c3891a8037';
  };

  return (
    <div className="home-container">
      <h1 className="centered-title">El Paso Business Solutions</h1>
      <div className="button-container">
        <a href="ContactPage">
          <button className="transparent-button contact-button">Contact Us</button>
        </a>
        <button className="transparent-button download-button" onClick={handleDownloadClick}>Upload</button>
      </div>
    </div>
  );
};

export default Home;
