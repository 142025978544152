import './Style.css';

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const TaskbarComponent = () => {
  useEffect(() => {
    const taskbar = document.getElementById('taskbar');
    const menuToggle = document.querySelector('.menu-toggle');
    const options = document.querySelector('.options');
    let prevScrollPos = window.pageYOffset;

    // Show/hide taskbar on scroll
    window.onscroll = function () {
      const currentScrollPos = window.pageYOffset;
      if (prevScrollPos > currentScrollPos) {
        taskbar.style.top = '0';
      } else {
        taskbar.style.top = `-${taskbar.offsetHeight}px`;
      }
      prevScrollPos = currentScrollPos;
    };

    // Toggle menu options visibility on click
    if (menuToggle) {
      menuToggle.addEventListener('click', () => {
        options.classList.toggle('show');
      });
    }
  }, []);

  return (
    <div className="taskbar" id="taskbar">
      <button className="menu-toggle">☰</button>
      <ul className="options">
        <li><Link to="/">Home</Link></li>
        <li><Link to="/PersonalTaxes">Personal Taxes</Link></li>
        <li><Link to="/BusinessTaxes">Business Taxes</Link></li>
        <li><Link to="/Incorporations">Incorporations</Link></li>
        <li><Link to="/NotaryServices">Notary Services</Link></li>
      </ul>
    </div>
  );
};

export default TaskbarComponent;
